import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTools, faChartBar, faHome, faUserCircle, faSearch, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

import { debounce } from 'lodash';
import axios from 'axios';
import SignInModal from './SignInModal';
import { handleTokenAndPostRequest } from '../utils/utils';

const MainNav = React.memo(({ agentDetails }) => {
  const { logo_url, agent_colour, landing_page_slug, agent_button_colour } = agentDetails;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectKey, setSelectKey] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1200);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const jwtToken = localStorage.getItem('jwt_token');
    if (jwtToken) {
      handleTokenAndPostRequest(`${window.api_host}/authenticate-token`, {})
        .then(response => {
          setUserEmail(response.data.email);
        })
        .catch(error => {
          console.error('Error authenticating token:', error);
          localStorage.removeItem('jwt_token');
        });
    }
  }, []);

  useEffect(() => {
    setSelectedProperty(null);
    setSelectKey(prevKey => prevKey + 1);
  }, [location]);

  const getLinkStyle = (path) => ({
    textAlign: 'center',
    color: location.pathname === path ? agent_button_colour : agent_button_colour,
    fontSize: isMobile ? '0.8rem' : '1rem',
  });

  const getIconStyle = (path) => ({
    color: location.pathname === path ? agent_button_colour : agent_button_colour,
  });

  const loadProperties = debounce((inputValue, callback) => {
    axios.get(`${window.api_host}/property-search?q=${inputValue}`)
      .then(response => {
        const options = response.data.map(property => ({
          label: property.address_text,
          value: property.id,
          slug: property.slug
        }));
        callback(options);
      })
      .catch(error => {
        console.error('Error loading property options:', error);
        callback([]);
      });
  }, 500);

  const handlePropertyChange = (selectedOption) => {
    setSelectedProperty(selectedOption);
    if (selectedOption) {
      const property_id = selectedOption.value;
      const slug = selectedOption.slug;
      const agentSlug = agentDetails.landing_page_slug;
      console.log('Property selected:', property_id, slug, agentSlug);
      navigate(`/property-profile/${slug}-${property_id}/${agentSlug}`);
    }
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faSearch} style={{ padding: '0px', marginRight: '8px', marginTop: '0px', marginLeft: '0px' }} />
        </div>
      </components.DropdownIndicator>
    );
  };

  const handleModalSuccess = (email) => {
    console.log("Modal success", email);
    setUserEmail(email);
  };

  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <FontAwesomeIcon icon={faSearch} size="lg" style={{color: agent_button_colour, marginLeft: '10px'}}/> {children}
    </components.Control>
  );

  const handleLogout = () => {
    localStorage.removeItem('jwt_token');
    setUserEmail(null);
    // refresh the page to clear the state
    window.location.reload();

  };

  return (
    <>
      {!isMobile ? (
        <Navbar expand="lg" style={{ backgroundColor: agent_colour, position: 'fixed', top: 0, width: '100%', zIndex: 9999 }}>
          <Container style={{ width: '100%' }}>
            <Navbar.Brand as={Link} to={`/${landing_page_slug}`}>
              <img
                src={logo_url}
                height="60"
                className="d-inline-block align-top"
                alt="Agent logo"
              />
            </Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link as={Link} to={`/property-search/${landing_page_slug}`} style={getLinkStyle(`/property-search/${landing_page_slug}`)}>Download Reports</Nav.Link>
              <Nav.Link as={Link} to={`/property-search-live/${landing_page_slug}`} style={getLinkStyle(`/property-search-live/${landing_page_slug}`)}>
                Valuation Tool <Badge bg="primary" ref={(el) => {
                  if (el) {
                    el.style.setProperty('background-color', agent_button_colour, 'important');
                  }
                }}>New</Badge>
              </Nav.Link>
              <Nav.Link as={Link} to={`/suburb-search/${landing_page_slug}`} style={getLinkStyle(`/suburb-search/${landing_page_slug}`)}>Suburb Analysis</Nav.Link>
              <Nav.Link as={Link} to={`/my-properties/${landing_page_slug}`} style={getLinkStyle(`/my-properties/${landing_page_slug}`)}>My Properties</Nav.Link>
            </Nav>
            <Nav className="ms-auto">
              <AsyncSelect
                key={selectKey}
                cacheOptions
                defaultOptions
                loadOptions={loadProperties}
                onChange={handlePropertyChange}
                value={selectedProperty}
                noOptionsMessage={() => null}
                placeholder="Estimate the price for 5m+ properties"
                openMenuOnClick={false}
                components={{ Control, DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '0.375rem',
                    textAlign: 'left',
                    width: '300px',
                    marginRight: '0px',
                    boxShadow: 'none',
                    borderColor: '#ced4da',
                    '&:hover': {
                      borderColor: '#ced4da',
                    },
                    fontSize: '0.7rem',
                    paddingLeft: '0px',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                    fontSize: '0.7rem',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                    fontSize: '0.7rem',
                    maxWidth: '100vw',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                  })
                }}
              />
              {userEmail ? (
                <Dropdown align="end">
                  <Dropdown.Toggle variant="link" id="dropdown-basic" style={{ color: agent_button_colour, textDecoration: 'none' }}>
                    <FontAwesomeIcon icon={faUserCircle} size="2x" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ '--dropdown-active-bg': agent_colour }}>
                    <Dropdown.Item as={Link} to="/notifications" style={{ color: agent_button_colour }}>Notifications</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={handleLogout} style={{ color: agent_button_colour }}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Button
                  variant="primary"
                  style={{ backgroundColor: agent_button_colour, borderColor: agent_button_colour, color: 'white', marginLeft: '10px' }}
                  onClick={() => setShowSignInModal(true)}
                >
                  Login / Sign Up
                </Button>
              )}
            </Nav>
          </Container>
        </Navbar>
      ) : (
        <>
          <div style={{ 
            top: 0, 
            width: '100%', 
            backgroundColor: agent_colour, 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            padding: '10px', 
            zIndex: 9999,
            position: 'fixed'
          }}>
            <img
              src={logo_url}
              height="35"
              className="d-inline-block align-top"
              alt="Agent logo"
            />
            <div style={{ flex: 1, margin: '0 10px 0px 0px' }}>
              <AsyncSelect
                key={selectKey}
                cacheOptions
                defaultOptions
                loadOptions={loadProperties}
                onChange={handlePropertyChange}
                value={selectedProperty}
                noOptionsMessage={() => null}
                placeholder="5m+ property valuations"
                components={{ Control, DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                openMenuOnClick={false}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '0.375rem',
                    textAlign: 'left',
                    width: '100%',
                    boxShadow: 'none',
                    borderColor: '#ced4da',
                    '&:hover': {
                      borderColor: '#ced4da',
                    },
                    fontSize: '0.7rem',
                    paddingLeft: '0px',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                    fontSize: '0.7rem',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                    fontSize: '0.7rem',
                    maxWidth: '100vw',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                  })
                }}
              />
            </div>
            {userEmail ? (
              <Dropdown align="end">
                <Dropdown.Toggle variant="link" id="dropdown-basic" style={{ color: agent_button_colour, textDecoration: 'none' }}>
                  <FontAwesomeIcon icon={faUserCircle} size="2x" />
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ '--dropdown-active-bg': agent_colour }}>
                  <Dropdown.Item as={Link} to="/notifications" style={{ color: agent_button_colour }}>Notifications</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleLogout} style={{ color: agent_button_colour }}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <FontAwesomeIcon 
                icon={faUserCircle} 
                size="2x" 
                style={{ color: agentDetails.agent_button_colour, cursor: 'pointer', marginTop: '2px' }}
                onClick={() => setShowSignInModal(true)} 
              />
            )}
          </div>
          <div style={{ 
            bottom: 0, 
            position: 'fixed',
            width: '100%', 
            backgroundColor: agent_colour, 
            display: 'flex', 
            justifyContent: 'space-around', 
            padding: '10px 0', 
            zIndex: 9999
          }}>
            <Nav.Link as={Link} to={`/property-search/${landing_page_slug}`} style={getLinkStyle(`/property-search/${landing_page_slug}`)}>
              <FontAwesomeIcon icon={faDownload} size="lg" style={getIconStyle(`/property-search/${landing_page_slug}`)} />
              <div>Reports</div>
            </Nav.Link>
            <Nav.Link as={Link} to={`/property-search-live/${landing_page_slug}`} style={getLinkStyle(`/property-search-live/${landing_page_slug}`)}>
              <FontAwesomeIcon icon={faTools} size="lg" style={getIconStyle(`/property-search-live/${landing_page_slug}`)} />
              <div>New Valuation</div>
            </Nav.Link>
            <Nav.Link as={Link} to={`/suburb-search/${landing_page_slug}`} style={getLinkStyle(`/suburb-search/${landing_page_slug}`)}>
              <FontAwesomeIcon icon={faChartBar} size="lg" style={getIconStyle(`/suburb-search/${landing_page_slug}`)} />
              <div>Suburbs</div>
            </Nav.Link>
            <Nav.Link as={Link} to={`/my-properties/${landing_page_slug}`} style={getLinkStyle(`/my-properties/${landing_page_slug}`)}>
              <FontAwesomeIcon icon={faHome} size="lg" style={getIconStyle(`/my-properties/${landing_page_slug}`)} />
              <div>My Properties</div>
            </Nav.Link>
          </div>
        </>
      )}
      <SignInModal
        show={showSignInModal}
        onHide={() => setShowSignInModal(false)}
        agentDetails={agentDetails}
        onSuccess={handleModalSuccess}
      />
    </>
  );
});

export default MainNav;